import anime from '../libs/anime'

class Module_31_Travel {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.tab = document.querySelectorAll('.module-31-travel .travel-tab');
    };

    let registerEvents = () => { 
      if(document.querySelector('.module-31-travel .travel-tab')) {
        for (let i = 0; i < cache.tab.length; i++) {
          cache.tab[i].querySelector('.tab_container').style.height =  cache.tab[i].querySelector('.tab').offsetHeight + "px";
          const tabName = cache.tab[i].querySelectorAll('.tab_name');
          for (let ii = 0; ii < tabName.length; ii++) {
            tabName[ii].addEventListener("click", switchTab);
          }
        }
      }
    };

    let switchTab = function() {
      const parent = this.closest('.travel-tab');
      const activeTabName = parent.querySelector('.tab_name.active');
      const targetTabName = this;
      const activeTab = parent.querySelector('.'+activeTabName.dataset.tab);
      const targetTab = parent.querySelector('.'+targetTabName.dataset.tab);

      activeTabName.classList.remove('active');
      targetTabName.classList.add('active');

      anime({
        targets: activeTab,
        opacity: 0,
        translateY: ['0%','10%'],
        duration: 600,
        easing: 'easeOutCubic',
        comple: function() {
          activeTab.style.display = "none";
        }
      });

      targetTab.style.display = "flex";
      anime({
        targets: parent.querySelector('.tab_container'),
        height: targetTab.offsetHeight,
        duration: 600,
        delay: 400,
        easing: 'easeOutCubic',
      });
      anime({
        targets: targetTab,
        opacity: [0,1],
        translateY: ['10%','0%'],
        duration: 600,
        delay: 400,
        easing: 'easeOutCubic',
      });

    }

    initFunc();
  };
}
export { Module_31_Travel }