import anime from '../libs/anime'
import Hammer from '../libs/hammer'

class Module_33_Infos {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.slider = document.querySelectorAll('.module-33-infos');
    };

    let registerEvents = () => {  
      for (let i = 0; i < cache.slider.length; ++i) {
        cache.slider[i].querySelector('.arrow_left').addEventListener("click", slideLeft);
        cache.slider[i].querySelector('.arrow_right').addEventListener("click", slideRight);

        let hammertime = new Hammer(cache.slider[i].querySelector('.container'));
        hammertime.on('swipe', function(ev) {
            if((ev.direction == 2) && (!cache.slider[i].querySelector('.arrow_right').classList.contains('not-active'))) {
                cache.slider[i].querySelector('.arrow_right').click();
            }
            if((ev.direction == 4) && (!cache.slider[i].querySelector('.arrow_left').classList.contains('not-active'))) {
                cache.slider[i].querySelector('.arrow_left').click();
            }
        });
      }
    };

    let slideRight = function() {
        const slider = this.closest('.container');
        const slides = slider.querySelectorAll('.card_info');
        let index = parseInt(slider.dataset.index);

        const currentSlide = slides[index];

        if(index < slides.length-1) {
            index++;
        } else {
            index = 0;
        }
        slider.dataset.index = index;

        const targetSlide = slides[index];
        currentSlide.style.zIndex = 3;
        targetSlide.style.pointerEvents = "all";
        targetSlide.style.zIndex = 2;

        anime({
            targets: currentSlide,
            duration: 400,
            translateX: ['-50%','-50%'],
            translateY: ['-50%','-50%'],
            backgroundColor: ['#FBFBFA', '#E7E4DE5'],
            rotate: [0,-6],
            opacity: [1,0],
            easing: 'easeOutQuad',
            complete: function() {
                currentSlide.style.pointerEvents = "none";
                currentSlide.querySelector('.content').style.opacity = 0;
            }
        });

        anime({
            targets: targetSlide,
            duration: 400,
            translateX: ['-50%','-50%'],
            translateY: ['-50%','-50%'],
            backgroundColor: ['#E7E4DE', '#FBFBFA'],
            rotate: [6,0],
            easing: 'easeOutQuad',
        });
        anime({
            targets: targetSlide.querySelector('.content'),
            duration: 300,
            scale: [0.9, 1],
            opacity: [0,1],
            delay: 400,
            easing: 'easeOutQuad',
        });
        
        let nxtSlide;
        if(index < slides.length-1) {
            nxtSlide = slides[index+1];
        } else {
            nxtSlide = slides[0];
        }

        nxtSlide.style.opacity = 0;
        nxtSlide.style.zIndex = 0;
        
        anime({
            targets: nxtSlide,
            duration: 400,
            opacity: [0,1],
            rotate: [12,6],
            translateX: ['-50%','-50%'],
            translateY: ['-50%','-50%'],
            backgroundColor: '#E7E4DE',
            easing: 'easeInOutCubic',
        });

    }



    let slideLeft = function() {
        const slider = this.closest('.container');
        const slides = slider.querySelectorAll('.card_info');
        let index = parseInt(slider.dataset.index);

        const currentSlide = slides[index];

        let prevSlide;
        if(index < slides.length-1) {
            prevSlide = slides[index+1];
        } else {
            prevSlide = slides[0];
        }
        prevSlide.style.zIndex = 0;
        anime({
            targets: prevSlide,
            duration: 400,
            opacity: [1,0],
            rotate: [6,12],
            translateX: ['-50%','-50%'],
            translateY: ['-50%','-50%'],
            easing: 'easeInOutCubic',
        });

        if(index > 0) {
            index--;
        } else {
            index = slides.length-1;
        }
        slider.dataset.index = index;

        const targetSlide = slides[index];
        currentSlide.style.zIndex = 2;
        targetSlide.style.pointerEvents = "all";
        currentSlide.style.pointerEvents = "none";
        targetSlide.style.zIndex = 3;

        anime({
            targets: targetSlide,
            duration: 400,
            rotate: [-6,0],
            translateX: ['-50%','-50%'],
            translateY: ['-50%','-50%'],
            backgroundColor: ['#E7E4DE', '#FBFBFA'],
            opacity: [0,1],
            easing: 'easeOutQuad',
        });
        anime({
            targets: targetSlide.querySelector('.content'),
            delay: 200,
            duration: 300,
            scale: [0.9, 1],
            opacity: [0,1],
            easing: 'easeOutQuad',
        });

        anime({
            targets: currentSlide,
            duration: 400,
            rotate: [0,6],
            translateX: ['-50%','-50%'],
            translateY: ['-50%','-50%'],
            backgroundColor: ['#FBFBFA','#E7E4DE'],
            easing: 'easeOutQuad',
        });
        anime({
            targets: currentSlide.querySelector('.content'),
            duration: 300,
            scale: [1,0.9],
            opacity: [1,0],
            easing: 'easeOutQuad',
        });
        
    }

    initFunc();
  };
}
export { Module_33_Infos }