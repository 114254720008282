import anime from '../libs/anime'
import globalVar from '../utils/globalVar'
import utils from '../utils/utils'
import Hammer from '../libs/hammer'

class Module_34_Realisations {
  constructor() {
    let cache = {};
    let scrollPos;
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      if(document.querySelector('.module-34-realisations.layout_slider')) {
        cache.slider = document.querySelectorAll('.module-34-realisations.layout_slider');
      }
      if(document.querySelector('.module-34-realisations .scroll_container .slider_container')) { 
        cache.slider2 = document.querySelectorAll('.module-34-realisations .scroll_container .slider_container');
      }
      if(document.querySelector('.module-34-realisations .scroll_container .scroll')) { 
        cache.scroll = document.querySelectorAll('.module-34-realisations .scroll_container .scroll');
        cache.anchor = document.querySelectorAll('.module-34-realisations .scroll_container .anchor');
      }
    };

    let registerEvents = () => {  
      if(document.querySelector('.module-34-realisations.layout_slider')) {
        for (let i = 0; i < cache.slider.length; ++i) {
          if(cache.slider[i].querySelector('.arrow_left')) {
            cache.slider[i].querySelector('.arrow_left').addEventListener("click", slideLeft);
            cache.slider[i].querySelector('.arrow_right').addEventListener("click", slideRight);
          }
          let hammertime = new Hammer(cache.slider[i].querySelector('.real-slider'));
          hammertime.on('swipe', function(ev) {
              if(ev.direction == 2) {
                  cache.slider[i].querySelector('.arrow_right').click();
              }
              if(ev.direction == 4) {
                  cache.slider[i].querySelector('.arrow_left').click();
              }
          });
        }
      }
      if(document.querySelector('.module-34-realisations .scroll_container .slider_container')) { 
        for (let i = 0; i < cache.slider2.length; ++i) {
          if(cache.slider2[i].querySelector('.arrow_left')) {
            cache.slider2[i].querySelector('.arrow_left').addEventListener("click", slideLeft2);
            cache.slider2[i].querySelector('.arrow_right').addEventListener("click", slideRight2);
          }
          let hammertime = new Hammer(cache.slider2[i]);
          hammertime.on('swipe', function(ev) {
              if(ev.direction == 2) {
                  cache.slider2[i].querySelector('.arrow_right').click();
              }
              if(ev.direction == 4) {
                  cache.slider2[i].querySelector('.arrow_left').click();
              }
          });
        }
      }
      if(document.querySelector('.module-34-realisations .scroll_container .scroll')) { 
        window.addEventListener('scroll', scrollDetect);
        for (let i = 0; i < cache.scroll.length; i++) {
          cache.scroll[i].addEventListener("click", scrollTo);
        }
      }
    };

    let slideLeft = function() {
      const slider = this.closest('.real-slider');
      const slidesImg = slider.querySelectorAll('.slider-wrap_img .slide_img');
      const slidesCat = slider.querySelectorAll('.slider-wrap_cat .cat');
      const slidesTitle = slider.querySelectorAll('.slider-wrap_title h3');
      const sliderNav = slider.querySelector('.slider_nav');
      let index = parseInt(slider.dataset.index);
      sliderNav.style.pointerEvents = "none";

      const currentSlideImg = slidesImg[index];
      const currentSlideCat = slidesCat[index];
      const currentSlideTitle = slidesTitle[index];
      
      if(index > 0) {
          index--;
      } else {
        index = slidesImg.length-1;
      }

      slider.dataset.index = index;
      sliderNav.querySelector('.nb_slide .nb').innerHTML = index+1;
      const targetSlideImg = slidesImg[index];
      const targetSlideCat = slidesCat[index];
      const targetSlideTitle = slidesTitle[index];

      anime({
          targets: currentSlideImg,
          duration: 800,
          opacity: [1,0],
          easing: 'easeInOutCubic',
          complete: function() {
            sliderNav.style.pointerEvents = "all";
          }
      });
      anime({
        targets: targetSlideImg,
        duration: 800,
        opacity: [0,1],
        easing: 'easeInOutCubic',
      });

      anime({
        targets: currentSlideCat,
        duration: 800,
        opacity: [1,0],
        translateX: ['0%','-5%'],
        easing: 'easeInOutCubic',
      });
      anime({
        targets: targetSlideCat,
        duration: 800,
        opacity: [0,1],
        translateX: ['5%','0%'],
        easing: 'easeInOutCubic',
      });

      anime({
        targets: currentSlideTitle,
        duration: 800,
        opacity: [1,0],
        delay: 50,
        translateX: ['0%','-5%'],
        easing: 'easeInOutCubic',
      });
      anime({
        targets: targetSlideTitle,
        duration: 800,
        opacity: [0,1],
        delay: 50,
        translateX: ['5%','0%'],
        easing: 'easeInOutCubic',
      });
      anime({
        targets: targetSlideTitle.closest('.slider-wrap_title'),
        duration: 800,
        height: targetSlideTitle.offsetHeight,
        delay: 50,
        easing: 'easeInOutCubic',
      });
    }

    let slideRight = function() {
      const slider = this.closest('.real-slider');
      const slidesImg = slider.querySelectorAll('.slider-wrap_img .slide_img');
      const slidesCat = slider.querySelectorAll('.slider-wrap_cat .cat');
      const slidesTitle = slider.querySelectorAll('.slider-wrap_title h3');
      const sliderNav = slider.querySelector('.slider_nav');
      let index = parseInt(slider.dataset.index);
      sliderNav.style.pointerEvents = "none";

      const currentSlideImg = slidesImg[index];
      const currentSlideCat = slidesCat[index];
      const currentSlideTitle = slidesTitle[index];
      
      if(index < slidesImg.length-1) {
          index++;
      } else {
        index = 0;
      }

      slider.dataset.index = index;
      sliderNav.querySelector('.nb_slide .nb').innerHTML = index+1;
      const targetSlideImg = slidesImg[index];
      const targetSlideCat = slidesCat[index];
      const targetSlideTitle = slidesTitle[index];

      anime({
          targets: currentSlideImg,
          duration: 800,
          opacity: [1,0],
          easing: 'easeInOutCubic',
          complete: function() {
            sliderNav.style.pointerEvents = "all";
          }
      });
      anime({
        targets: targetSlideImg,
        duration: 800,
        opacity: [0,1],
        easing: 'easeInOutCubic',
      });

      anime({
        targets: currentSlideCat,
        duration: 800,
        opacity: [1,0],
        translateX: ['0%','5%'],
        easing: 'easeInOutCubic',
      });
      anime({
        targets: targetSlideCat,
        duration: 800,
        opacity: [0,1],
        translateX: ['-5%','0%'],
        easing: 'easeInOutCubic',
      });

      anime({
        targets: currentSlideTitle,
        duration: 800,
        opacity: [1,0],
        delay: 50,
        translateX: ['0%','5%'],
        easing: 'easeInOutCubic',
      });
      anime({
        targets: targetSlideTitle,
        duration: 800,
        opacity: [0,1],
        delay: 50,
        translateX: ['-5%','0%'],
        easing: 'easeInOutCubic',
      });
      anime({
        targets: targetSlideTitle.closest('.slider-wrap_title'),
        duration: 800,
        height: targetSlideTitle.offsetHeight,
        delay: 50,
        easing: 'easeInOutCubic',
      });
    }


    let slideLeft2 = function() {
      const slider = this.closest('.slider_container');
      const slidesImg = slider.querySelectorAll('.slide_img');
      const sliderNav = slider.querySelector('.slider_nav');
      let index = parseInt(slider.dataset.index);
      sliderNav.style.pointerEvents = "none";

      const currentSlideImg = slidesImg[index];
      
      if(index > 0) {
          index--;
      } else {
        index = slidesImg.length-1;
      }

      slider.dataset.index = index;
      sliderNav.querySelector('.nb_slide .nb').innerHTML = index+1;
      const targetSlideImg = slidesImg[index];

      anime({
          targets: currentSlideImg,
          duration: 800,
          opacity: [1,0],
          easing: 'easeInOutCubic',
          complete: function() {
            sliderNav.style.pointerEvents = "all";
          }
      });
      anime({
        targets: targetSlideImg,
        duration: 800,
        opacity: [0,1],
        easing: 'easeInOutCubic',
      });
    }

    let slideRight2 = function() {
      const slider = this.closest('.slider_container');
      const slidesImg = slider.querySelectorAll('.slide_img');
      const sliderNav = slider.querySelector('.slider_nav');
      let index = parseInt(slider.dataset.index);
      sliderNav.style.pointerEvents = "none";

      const currentSlideImg = slidesImg[index];
      
      if(index < slidesImg.length-1) {
          index++;
      } else {
        index = 0;
      }

      slider.dataset.index = index;
      sliderNav.querySelector('.nb_slide .nb').innerHTML = index+1;
      const targetSlideImg = slidesImg[index];

      anime({
          targets: currentSlideImg,
          duration: 800,
          opacity: [1,0],
          easing: 'easeInOutCubic',
          complete: function() {
            sliderNav.style.pointerEvents = "all";
          }
      });
      anime({
        targets: targetSlideImg,
        duration: 800,
        opacity: [0,1],
        easing: 'easeInOutCubic',
      });

    }

    let scrollTo = function(e) {
      document.querySelector('body').classList.add('scroll');
      e.preventDefault();
      const target = document.querySelector(this.getAttribute("href"));
      console.log(this.getAttribute("href"));
      anime({
        targets: globalVar.scrollElement,
        scrollTop: (globalVar.scrollElement.scrollTop+target.getBoundingClientRect().top - 100),
        duration: 800,
        easing: 'easeOutQuad',
        complete: function () {
          document.querySelector('body').classList.remove('scroll');
        }
      });
    }

    let scrollDetect = function() {
      scrollPos = window.pageYOffset;

      for (let i = 0; i < cache.anchor.length; i++) {
        if((scrollPos+(window.innerHeight/2)) > cache.anchor[i].offsetTop && (!cache.scroll[i].classList.contains('active'))) {
            cache.scroll[i].parentNode.querySelector('a.active').classList.remove('active');
            cache.scroll[i].classList.add('active');
        }
      }

    }

    initFunc();
  };
}
export { Module_34_Realisations }