import anime from '../libs/anime'
import Hammer from '../libs/hammer'

class Module_29_Temoignage {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.container = document.querySelectorAll('.module-29-temoignage .tem-slider_container');
        cache.slider = document.querySelectorAll('.module-29-temoignage .tem-slider_container.tem-slider');
    };

    let registerEvents = () => {  
        for (let i = 0; i < cache.container.length; i++) {
            cache.container[i].style.height = cache.container[i].querySelector('.tem-slider_slide').offsetHeight+"px";
        }
      for (let i = 0; i < cache.slider.length; ++i) {
        cache.slider[i].querySelector('.arrow_left').addEventListener("click", slideLeft);
        cache.slider[i].querySelector('.arrow_right').addEventListener("click", slideRight);

        let hammertime = new Hammer(cache.slider[i]);
        hammertime.on('swipe', function(ev) {
            if((ev.direction == 2) && (!cache.slider[i].querySelector('.arrow_right').classList.contains('not-active'))) {
                cache.slider[i].querySelector('.arrow_right').click();
            }
            if((ev.direction == 4) && (!cache.slider[i].querySelector('.arrow_left').classList.contains('not-active'))) {
                cache.slider[i].querySelector('.arrow_left').click();
            }
        });
      }
    };

    let slideLeft = function() {
        const slider = this.closest('.tem-slider');
        const slides = slider.querySelectorAll('.tem-slider_slide');
        const sliderNav = this.closest('.tem-slider_nav');
        let index = parseInt(slider.dataset.index);
        sliderNav.style.pointerEvents = "none";

        const currentSlide = slides[index];

        if(index > 0) {
            index--;
        }
        else {
            index = slides.length-1
        }

        slider.dataset.index = index;
        const targetSlide = slides[index];

        anime({
            targets: slider,
            duration: 600,
            height: targetSlide.offsetHeight,
            easing: 'easeInOutCubic',
        });

        const currentContent = currentSlide.querySelectorAll('div');
        for (let i = 0; i < currentContent.length; i++) {
            anime({
                targets: currentContent[i],
                duration: 800,
                translateX: ['0%','5%'],
                delay: (50 * i),
                easing: 'easeOutCubic',
            });
        }

        anime({
            targets: currentSlide,
            duration: 800,
            opacity: [1,0],
            easing: 'easeInOutCubic',
        });

        anime({
            targets: targetSlide,
            duration: 800,
            opacity: [0,1],
            easing: 'easeInOutCubic',
            complete: function() {
                sliderNav.style.pointerEvents = "all";
            }
        });

        const targetContent = targetSlide.querySelectorAll('div');
        for (let i = 0; i < targetContent.length; i++) {
            anime({
                targets: targetContent[i],
                duration: 800,
                translateX: ['-5%','0%'],
                delay: (50 * i),
                easing: 'easeOutCubic',
            });
        }
        
    }


    let slideRight = function() {
        const slider = this.closest('.tem-slider');
        const slides = slider.querySelectorAll('.tem-slider_slide');
        const sliderNav = this.closest('.tem-slider_nav');
        let index = parseInt(slider.dataset.index);
        sliderNav.style.pointerEvents = "none";

        const currentSlide = slides[index];
        
        if(index < slides.length-1) {
            index++;
        } else {
            index = 0;
        }

        slider.dataset.index = index;
        const targetSlide = slides[index];

        anime({
            targets: slider,
            duration: 600,
            height: targetSlide.offsetHeight,
            easing: 'easeInOutCubic',
        });

        const currentContent = currentSlide.querySelectorAll('div');
        for (let i = 0; i < currentContent.length; i++) {
            anime({
                targets: currentContent[i],
                duration: 1000,
                translateX: ['0%','-5%'],
                delay: (50 * i),
                easing: 'easeOutCubic',
            });
        }

        anime({
            targets: currentSlide,
            duration: 800,
            opacity: [1,0],
            easing: 'easeInOutCubic',
        });

        anime({
            targets: targetSlide,
            duration: 800,
            opacity: [0,1],
            easing: 'easeInOutCubic',
            complete: function() {
                sliderNav.style.pointerEvents = "all";
            }
        });

        const targetContent = targetSlide.querySelectorAll('div');
        for (let i = 0; i < targetContent.length; i++) {
            anime({
                targets: targetContent[i],
                duration: 1000,
                translateX: ['5%','0%'],
                delay: (50 * i),
                easing: 'easeOutCubic',
            });
        }
        
    }
    initFunc();
  };
}
export { Module_29_Temoignage }