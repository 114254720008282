import anime from '../libs/anime'
import Hammer from '../libs/hammer'

class Module_25_dest {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.slider = document.querySelectorAll('.module-25-dest .slider-dest_container.slider-dest');
    };

    let registerEvents = () => {  
      for (let i = 0; i < cache.slider.length; ++i) {
        cache.slider[i].querySelector('.arrow_left').addEventListener("click", slideLeft);
        cache.slider[i].querySelector('.arrow_right').addEventListener("click", slideRight);

        let hammertime = new Hammer(cache.slider[i]);
        hammertime.on('swipe', function(ev) {
            if((ev.direction == 2) && (!cache.slider[i].querySelector('.arrow_right').classList.contains('not-active'))) {
                cache.slider[i].querySelector('.arrow_right').click();
            }
            if((ev.direction == 4) && (!cache.slider[i].querySelector('.arrow_left').classList.contains('not-active'))) {
                cache.slider[i].querySelector('.arrow_left').click();
            }
        });
      }
    };

    let slideLeft = function() {
        const slider = this.closest('.slider-dest');
        const slides = slider.querySelectorAll('.slider-dest_slide');
        const sliderNav = this.closest('.slider-dest_nav');
        let index = parseInt(slider.dataset.index);
        sliderNav.style.pointerEvents = "none";

        const currentSlide = slides[index];

        if(index > 0) {
            index--;
        }
        else {
            index = slides.length-1
        }

        slider.dataset.index = index;
        slider.querySelector('.nb_slide .current').innerHTML=index+1;
        const targetSlide = slides[index];

        targetSlide.style.display = "block";

        anime({
            targets: currentSlide.querySelector('.titre'),
            duration: 1000,
            translateX: ['0%','50%'],
            opacity: [1,0],
            easing: 'easeOutCubic',
        });

        anime({
            targets: currentSlide,
            duration: 800,
            delay: 50,
            translateX: ['0%','100%'],
            easing: 'easeInOutCubic',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });

        anime({
            targets: targetSlide,
            duration: 800,
            delay: 50,
            translateX: ['-100%','0%'],
            easing: 'easeInOutCubic',
            complete: function() {
                sliderNav.style.pointerEvents = "all";
            }
        });

        anime({
            targets: targetSlide.querySelector('.titre'),
            duration: 800,
            delay: 500,
            translateX: ['-50%','0%'],
            opacity: [0,1],
            easing: 'easeOutCubic',
        });
        
    }



    let slideRight = function() {
        const slider = this.closest('.slider-dest');
        const slides = slider.querySelectorAll('.slider-dest_slide');
        const sliderNav = this.closest('.slider-dest_nav');
        let index = parseInt(slider.dataset.index);
        sliderNav.style.pointerEvents = "none";

        const currentSlide = slides[index];
        
        if(index < slides.length-1) {
            index++;
        } else {
            index = 0;
        }

        slider.dataset.index = index;
        slider.querySelector('.nb_slide .current').innerHTML=index+1;
        const targetSlide = slides[index];

        targetSlide.style.display = "block";

        anime({
            targets: currentSlide.querySelector('.titre'),
            duration: 1000,
            translateX: ['0%','-30%'],
            opacity: [1,0],
            easing: 'easeOutCubic',
        });

        anime({
            targets: currentSlide,
            duration: 800,
            delay: 50,
            translateX: ['0%','-100%'],
            easing: 'easeInOutCubic',
            complete: function() {
                currentSlide.style.display = "none";
            }
        });

        anime({
            targets: targetSlide,
            duration: 800,
            delay: 50,
            translateX: ['100%','0%'],
            easing: 'easeInOutCubic',
            complete: function() {
                sliderNav.style.pointerEvents = "all";
            }
        });
        
        anime({
            targets: targetSlide.querySelector('.titre'),
            duration: 800,
            delay: 500,
            translateX: ['50%','0%'],
            opacity: [0,1],
            easing: 'easeOutCubic',
        });
        
    }
    initFunc();
  };
}
export { Module_25_dest }