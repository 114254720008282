import anime from '../libs/anime'
import Hammer from '../libs/hammer'

class Module_28_Office {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.slider = document.querySelectorAll('.module-28-office.layout-slider');
    };

    let registerEvents = () => {  
      for (let i = 0; i < cache.slider.length; ++i) {
        if(cache.slider[i].querySelector('.arrow_left')) {
          cache.slider[i].querySelector('.arrow_left').addEventListener("click", slideLeft);
          cache.slider[i].querySelector('.arrow_right').addEventListener("click", slideRight);
        }
      }
    };

    let slideLeft = function() {
        const parent = this.closest('.module-28-office');
        const slider = parent.querySelector('.office_slider');
        const arrRight = parent.querySelector('.arrow_right');
        const dist = slider.scrollLeft - slider.offsetWidth/1.5;
        if(arrRight.classList.contains('not_active')) {
            arrRight.classList.remove('not_active');
        }
        if(dist < slider.offsetWidth*1.5){
            parent.querySelector('.arrow_left').classList.add('not_active');
        }
        anime({
            targets: slider,
            scrollLeft: dist,
            duration: 100,
            easing: 'easeOutCubic',
        });
    }
    let slideRight = function() {
        const parent = this.closest('.module-28-office');
        const slider = parent.querySelector('.office_slider');
        const arrLeft = parent.querySelector('.arrow_left');
        const dist = slider.scrollLeft + slider.offsetWidth/1.5;
        if(arrLeft.classList.contains('not_active')) {
            arrLeft.classList.remove('not_active');
        }
        if(dist > (slider.scrollWidth - (slider.offsetWidth*1.5))){
            parent.querySelector('.arrow_right').classList.add('not_active');
        }
        anime({
            targets: slider,
            scrollLeft: dist,
            duration: 100,
            easing: 'easeOutCubic',
        });
    }

    initFunc();
  };
}
export { Module_28_Office }