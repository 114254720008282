import anime from '../libs/anime'
import Hammer from '../libs/hammer'

class Module_21_SliderChrono {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.slider = document.querySelectorAll('.module-21-slider_chrono');
    };

    let registerEvents = () => {  
      for (let i = 0; i < cache.slider.length; ++i) {
        cache.slider[i].querySelector('.arrow_left').addEventListener("click", slideLeft);
        cache.slider[i].querySelector('.arrow_right').addEventListener("click", slideRight);

        let hammertime = new Hammer(cache.slider[i].querySelector('.slider_container'));
        hammertime.on('swipe', function(ev) {
            if((ev.direction == 2) && (!cache.slider[i].querySelector('.arrow_right').classList.contains('not-active'))) {
                cache.slider[i].querySelector('.arrow_right').click();
            }
            if((ev.direction == 4) && (!cache.slider[i].querySelector('.arrow_left').classList.contains('not-active'))) {
                cache.slider[i].querySelector('.arrow_left').click();
            }
        });
      }
    };

    let slideLeft = function() {
        const slider = this.closest('.module-21-slider_chrono');
        const slidesImg = slider.querySelectorAll('.slider-img_slide');
        const slidesTxt = slider.querySelectorAll('.slider-content_slide');
        const bullets = slider.querySelectorAll('.slider-bullets .bullet');
        const sliderNav = this.closest('.slider_nav');
        let index = parseInt(slider.dataset.index);
        sliderNav.style.pointerEvents = "none";

        const currentSlideImg = slidesImg[index];
        const currentSlideTxt = slidesTxt[index];
        bullets[index].classList.remove('active');

        if(index < slidesImg.length-1) {
            const nxtSlideTxt = slidesTxt[index+1];
            anime({
                targets: nxtSlideTxt,
                duration: 800,
                translateX: ['50%','100%'],
                easing: 'easeInOutCubic',
                complete: function() {
                    nxtSlideTxt.style.display = "none";
                }
            });
        }
        
        if(index > 0) {
            index--;
            if(slider.querySelector('.arrow_right').classList.contains('not-active')) {
                slider.querySelector('.arrow_right').classList.remove('not-active');
            }
        }
        slider.dataset.index = index;
        const targetSlideImg = slidesImg[index];
        const targetSlideTxt = slidesTxt[index];
        bullets[index].classList.add('active');
        targetSlideImg.style.display = "block";

        anime({
            targets: currentSlideImg,
            duration: 800,
            translateX: ['0%','100%'],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: targetSlideImg,
            duration: 800,
            translateX: ['-100%','0%'],
            easing: 'easeInOutCubic',
            complete: function() {
                sliderNav.style.pointerEvents = "all";
                currentSlideImg.style.display = "none";
                if(index == 0) {
                    slider.querySelector('.arrow_left').classList.add('not-active');
                }
            }
        });

        anime({
            targets: currentSlideTxt,
            duration: 800,
            delay: 100,
            translateX: ['0%','50%'],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: currentSlideTxt.querySelector('.date'),
            duration: 800,
            delay: 100,
            color: ['rgba(251, 251, 250, 1)', 'rgba(251, 251, 250, 0)'],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: currentSlideTxt.querySelector('.txt'),
            duration: 800,
            delay: 100,
            opacity: 0,
            skewX: [0, -75],
            easing: 'easeInOutCubic',
        });

        anime({
            targets: targetSlideTxt,
            duration: 800,
            delay: 150,
            translateX: ['-50%','0%'],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: targetSlideTxt.querySelector('.date'),
            duration: 800,
            delay: 150,
            color: ['rgba(251, 251, 250, 0)', 'rgba(251, 251, 250, 1)'],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: targetSlideTxt.querySelector('.txt'),
            duration: 800,
            delay: 100,
            opacity: [0,1],
            skewX: [75, 0],
            easing: 'easeInOutCubic',
        });

        if(index > 0) {
            const prevSlideTxt = slidesTxt[index-1];
            prevSlideTxt.style.display = "block";
            anime({
                targets: prevSlideTxt,
                duration: 800,
                delay: 200,
                translateX: ['-100%','-50%'],
                easing: 'easeInOutCubic',
            });
        }
        
    }



    let slideRight = function() {
        const slider = this.closest('.module-21-slider_chrono');
        const slidesImg = slider.querySelectorAll('.slider-img_slide');
        const slidesTxt = slider.querySelectorAll('.slider-content_slide');
        const bullets = slider.querySelectorAll('.slider-bullets .bullet');
        const sliderNav = this.closest('.slider_nav');
        let index = parseInt(slider.dataset.index);
        sliderNav.style.pointerEvents = "none";

        const currentSlideImg = slidesImg[index];
        const currentSlideTxt = slidesTxt[index];
        bullets[index].classList.remove('active');

        if(index > 0) {
            const prevSlideTxt = slidesTxt[index-1];
            anime({
                targets: prevSlideTxt,
                duration: 800,
                translateX: ['-50%','-100%'],
                easing: 'easeInOutCubic',
                complete: function() {
                    prevSlideTxt.style.display = "none";
                }
            });
        }
        
        if(index < slidesImg.length-1) {
            index++;
            if(slider.querySelector('.arrow_left').classList.contains('not-active')) {
                slider.querySelector('.arrow_left').classList.remove('not-active');
            }
        }
        slider.dataset.index = index;
        const targetSlideImg = slidesImg[index];
        const targetSlideTxt = slidesTxt[index];
        bullets[index].classList.add('active');
        targetSlideImg.style.display = "block";

        anime({
            targets: currentSlideImg,
            duration: 800,
            translateX: ['0%','-100%'],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: targetSlideImg,
            duration: 800,
            translateX: ['100%','0%'],
            easing: 'easeInOutCubic',
            complete: function() {
                sliderNav.style.pointerEvents = "all";
                currentSlideImg.style.display = "none";
                if(index == slidesImg.length-1) {
                    slider.querySelector('.arrow_right').classList.add('not-active');
                }
            }
        });
        

        anime({
            targets: currentSlideTxt,
            duration: 800,
            delay: 100,
            translateX: ['0%','-50%'],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: currentSlideTxt.querySelector('.date'),
            duration: 800,
            delay: 100,
            color: ['rgba(251, 251, 250, 1)', 'rgba(251, 251, 250, 0)'],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: currentSlideTxt.querySelector('.txt'),
            duration: 800,
            delay: 100,
            opacity: 0,
            skewX: [0, 75],
            easing: 'easeInOutCubic',
        });

        anime({
            targets: targetSlideTxt,
            duration: 800,
            delay: 150,
            translateX: ['50%','0%'],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: targetSlideTxt.querySelector('.date'),
            duration: 800,
            delay: 150,
            color: ['rgba(251, 251, 250, 0)', 'rgba(251, 251, 250, 1)'],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: targetSlideTxt.querySelector('.txt'),
            duration: 800,
            delay: 100,
            opacity: [0,1],
            skewX: [-75, 0],
            easing: 'easeInOutCubic',
        });

        if(index < slidesImg.length-1) {
            const nxtSlideTxt = slidesTxt[index+1];
            nxtSlideTxt.style.display = "block";
            anime({
                targets: nxtSlideTxt,
                duration: 800,
                delay: 200,
                translateX: ['100%','50%'],
                easing: 'easeInOutCubic',
            });
        }
    }
    initFunc();
  };
}
export { Module_21_SliderChrono }