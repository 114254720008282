import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Module_13_Collapse__Module_19_Faq{
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.collapse = document.querySelectorAll('.module-13-content_collapse .collapse_title, .module-19-faq .collapse_title, .template-voyage .collapse_container .collapse_title');
    };

    let registerEvents = () => {
        for (let i = 0; i < cache.collapse.length; i++) {
            cache.collapse[i].addEventListener('click',toggleCol);
        }
    };

    let toggleCol = function() {
        const col = this.closest('.collapse');
        
        if(!col.classList.contains('active')) {
            const parent = col.closest('.collapse_container');
            const active = parent.querySelector('.collapse.active');
            active.classList.remove('active');
            anime({
                targets: active.querySelector('.collapse_content'),
                duration: 600,
                height: 0,
                easing: 'easeInOutCubic',
                complete: function() {
                    active.querySelector('.collapse_content').style.display = "none";
                }
            });

            col.classList.add('active');
            const col_content = col.querySelector('.collapse_content');
            col_content.style.display = "block";
            col_content.style.height = "auto";
            const height = col_content.offsetHeight;
            col_content.style.height = 0;

            anime({
                targets: col_content,
                duration: 600,
                height: height,
                easing: 'easeInQuad',
                complete: function() {
                    // document.querySelector('body').classList.add('scroll');
                    // anime({
                    //     targets: globalVar.scrollElement,
                    //     scrollTop: (globalVar.scrollElement.scrollTop+col.getBoundingClientRect().top),
                    //     duration: 600,
                    //     easing: 'easeOutQuad',
                    //     complete: function () {
                    //         document.querySelector('body').classList.remove('scroll');
                    //     }
                    // });
                }
            });
        }
    }

    initFunc();
  };
}
export { Module_13_Collapse__Module_19_Faq }