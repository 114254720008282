import anime from '../libs/anime'
import Hammer from '../libs/hammer'

class Module_23_Team {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.slider = document.querySelectorAll('.module-23-team.layout-slider');
        cache.tab = document.querySelectorAll('.module-23-team.layout-tab');
    };

    let registerEvents = () => {  
      if(document.querySelector('.module-23-team.layout-slider')) {
        for (let i = 0; i < cache.slider.length; ++i) {
          cache.slider[i].querySelector('.arrow_left').addEventListener("click", slideLeft);
          cache.slider[i].querySelector('.arrow_right').addEventListener("click", slideRight);
        }
      }
      if(document.querySelector('.module-23-team.layout-tab')) {
        for (let i = 0; i < cache.tab.length; i++) {
          cache.tab[i].querySelector('.tab_container').style.height =  cache.tab[i].querySelector('.tab').offsetHeight + "px";
          const tabName = cache.tab[i].querySelectorAll('.tab_name');
          for (let ii = 0; ii < tabName.length; ii++) {
            tabName[ii].addEventListener("click", switchTab);
          }
        }
      }
    };

    let slideLeft = function() {
        const parent = this.closest('.module-23-team');
        const slider = parent.querySelector('.slider_team');
        const arrRight = parent.querySelector('.arrow_right');
        const dist = slider.scrollLeft - slider.offsetWidth/1.5;
        if(arrRight.classList.contains('not_active')) {
            arrRight.classList.remove('not_active');
        }
        if(dist < slider.offsetWidth*1.5){
            parent.querySelector('.arrow_left').classList.add('not_active');
        }
        anime({
            targets: slider,
            scrollLeft: dist,
            duration: 100,
            easing: 'easeOutCubic',
        });
    }
    let slideRight = function() {
        const parent = this.closest('.module-23-team');
        const slider = parent.querySelector('.slider_team');
        const arrLeft = parent.querySelector('.arrow_left');
        const dist = slider.scrollLeft + slider.offsetWidth/1.5;
        if(arrLeft.classList.contains('not_active')) {
            arrLeft.classList.remove('not_active');
        }
        if(dist > (slider.scrollWidth - (slider.offsetWidth*1.5))){
            parent.querySelector('.arrow_right').classList.add('not_active');
        }
        anime({
            targets: slider,
            scrollLeft: dist,
            duration: 100,
            easing: 'easeOutCubic',
        });
    }

    let switchTab = function() {
      const parent = this.closest('.tab_team');
      const activeTabName = parent.querySelector('.tab_name.active');
      const targetTabName = this;
      const activeTab = parent.querySelector('.'+activeTabName.dataset.tab);
      const targetTab = parent.querySelector('.'+targetTabName.dataset.tab);

      activeTabName.classList.remove('active');
      targetTabName.classList.add('active');

      anime({
        targets: activeTab,
        opacity: 0,
        translateY: ['0%','10%'],
        duration: 600,
        easing: 'easeOutCubic',
        comple: function() {
          activeTab.style.display = "none";
        }
      });

      targetTab.style.display = "flex";
      anime({
        targets: parent.querySelector('.tab_container'),
        height: targetTab.offsetHeight,
        duration: 600,
        delay: 400,
        easing: 'easeOutCubic',
      });
      anime({
        targets: targetTab,
        opacity: [0,1],
        translateY: ['10%','0%'],
        duration: 600,
        delay: 400,
        easing: 'easeOutCubic',
      });

    }

    initFunc();
  };
}
export { Module_23_Team }