import anime from '../libs/anime'
import Hammer from '../libs/hammer'

class Module_22_SliderKey {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.slider = document.querySelectorAll('.module-22-slider_key .slider-key_container');
    };

    let registerEvents = () => {  
      for (let i = 0; i < cache.slider.length; ++i) {
        cache.slider[i].querySelector('.arrow_left').addEventListener("click", slideLeft);
        cache.slider[i].querySelector('.arrow_right').addEventListener("click", slideRight);

        let hammertime = new Hammer(cache.slider[i].querySelector('.slider-key'));
        hammertime.on('swipe', function(ev) {
            if((ev.direction == 2) && (!cache.slider[i].querySelector('.arrow_right').classList.contains('not-active'))) {
                cache.slider[i].querySelector('.arrow_right').click();
            }
            if((ev.direction == 4) && (!cache.slider[i].querySelector('.arrow_left').classList.contains('not-active'))) {
                cache.slider[i].querySelector('.arrow_left').click();
            }
        });
      }
    };

    let slideLeft = function() {
        const slider = this.closest('.slider-key_container');
        const slides = slider.querySelectorAll('.slider-key_slide');
        const sliderNav = this.closest('.slider_nav');
        let index = parseInt(slider.dataset.index);
        sliderNav.style.pointerEvents = "none";

        const currentSlide = slides[index];

        let prevIndex = index;
        if(prevIndex < slides.length-1) {
            prevIndex++;
        } else {
            prevIndex = 0;
        }
        const prevSlide = slides[prevIndex];
        console.log(prevSlide);
        anime({
            targets: prevSlide,
            duration: 800,
            delay: 50,
            rotate: [17,34],
            easing: 'easeInOutCubic',
        });

        if(prevIndex < slides.length-1) {
            prevIndex++;
        } else {
            prevIndex = 0;
        }
        const prev2Slide = slides[prevIndex];
        anime({
            targets: prev2Slide,
            duration: 800,
            rotate: [34,51],
            easing: 'easeInOutCubic',
            complete: function() {
                prev2Slide.style.display = "none";
            }
        });

        
        if(index > 0) {
            index--;
        } else {
            index = slides.length-1;
        }
        slider.dataset.index = index;
        const targetSlide = slides[index];

        anime({
            targets: currentSlide,
            duration: 800,
            rotate: [0,17],
            delay: 100,
            easing: 'easeInOutCubic',
        });
        anime({
            targets: targetSlide,
            duration: 800,
            delay: 150,
            rotate: [-17,0],
            easing: 'easeInOutCubic',
        });
        

        if(index > 0) {
            index--;
        } else {
            index = slides.length-1;
        }
        const nxtSlide = slides[index];
        anime({
            targets: nxtSlide,
            duration: 800,
            delay: 200,
            rotate: [-34,-17],
            easing: 'easeInOutCubic',
        });

        let nxt2Slide;
        if(index > 0) {
            nxt2Slide = slides[index-1];
        } else {
            nxt2Slide = slides[slides.length-1];
        }
        nxt2Slide.style.display = "flex";
        anime({
            targets: nxt2Slide,
            duration: 800,
            delay: 250,
            rotate: [-51,-34],
            easing: 'easeInOutCubic',
            complete: function() {
                sliderNav.style.pointerEvents = "all";
            }
        });
    }



    let slideRight = function() {
        const slider = this.closest('.slider-key_container');
        const slides = slider.querySelectorAll('.slider-key_slide');
        const sliderNav = this.closest('.slider_nav');
        let index = parseInt(slider.dataset.index);
        sliderNav.style.pointerEvents = "none";

        const currentSlide = slides[index];

        let prevIndex = index;
        if(prevIndex > 0) {
            prevIndex--;
        } else {
            prevIndex = slides.length-1;
        }
        const prevSlide = slides[prevIndex];
        anime({
            targets: prevSlide,
            duration: 800,
            delay: 50,
            rotate: [-17,-34],
            easing: 'easeInOutCubic',
        });

        if(prevIndex > 0) {
            prevIndex--;
        } else {
            prevIndex = slides.length-1;
        }
        const prev2Slide = slides[prevIndex];
        anime({
            targets: prev2Slide,
            duration: 800,
            rotate: [-34,-51],
            easing: 'easeInOutCubic',
            complete: function() {
                prev2Slide.style.display = "none";
            }
        });

        
        
        if(index < slides.length-1) {
            index++;
        } else {
            index = 0;
        }
        slider.dataset.index = index;
        const targetSlide = slides[index];

        anime({
            targets: currentSlide,
            duration: 800,
            rotate: [0,-17],
            delay: 100,
            easing: 'easeInOutCubic',
        });
        anime({
            targets: targetSlide,
            duration: 800,
            delay: 150,
            rotate: [17,0],
            easing: 'easeInOutCubic',
        });
        

        if(index < slides.length-1) {
            index++;
        } else {
            index = 0;
        }
        const nxtSlide = slides[index];
        anime({
            targets: nxtSlide,
            duration: 800,
            delay: 200,
            rotate: [34,17],
            easing: 'easeInOutCubic',
        });

        let nxt2Slide;
        if(index < slides.length-1) {
            nxt2Slide = slides[index+1];
        } else {
            nxt2Slide = slides[0];
        }
        nxt2Slide.style.display = "flex";
        anime({
            targets: nxt2Slide,
            duration: 800,
            delay: 250,
            rotate: [51,34],
            easing: 'easeInOutCubic',
            complete: function() {
                sliderNav.style.pointerEvents = "all";
            }
        });

    }
    initFunc();
  };
}
export { Module_22_SliderKey }