import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Actualites {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.filtersWrap = document.querySelector('.filter-wrap');
        cache.filters = cache.filtersWrap.querySelectorAll('.filter');
        cache.archive = document.querySelector('.collapse_container');
    };

    let registerEvents = () => {
        for (let i = 0; i < cache.filters.length; i++) {
            cache.filters[i].addEventListener('click', filter);   
        }
        if(document.URL.indexOf("#") >= 0){ 
            const id = "#"+document.URL.split('#')[1];
            const el = document.querySelector(id);
            if(!el.classList.contains('active')) {
                el.querySelector('.collapse_title').click();
                setTimeout(function(){
                    const top = utils.getOffsetTop(el)-200;
                    anime({
                        targets: globalVar.scrollElement,
                        duration: 400,
                        scrollTop: [top],
                        easing: 'easeOutCubic',
                    });
                }, 400);
            }
        }
    };

    let filter = function() {
        const cat = this.dataset.filter;
        cache.filtersWrap.querySelector('.filter.active').classList.remove('active');
        this.classList.add('active');
        anime({
            targets: cache.archive,
            duration: 400,
            translateY: [0,'20%'],
            opacity: [1,0],
            easing: 'easeOutCubic',
            complete: function() {
                const actu = cache.archive.querySelectorAll('.collapse');
                for (let i = 0; i < actu.length; i++) {
                    if(actu[i].classList.contains(cat)) {
                        actu[i].style.display = "block"; 
                    } else {
                        actu[i].style.display = "none"; 
                    }
                }
                anime({
                    targets: cache.archive,
                    duration: 400,
                    delay: 200,
                    translateY: ['-20%',0],
                    opacity: [0,1],
                    easing: 'easeOutCubic',
                });
            }
        });
    }

    initFunc();
  };
}
export { Actualites }
