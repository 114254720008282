import anime from '../libs/anime'
import Hammer from '../libs/hammer'

class Module_01_HeaderSlider {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.slider = document.querySelectorAll('.module-01-header.type-slider');
    };

    let registerEvents = () => {  
      for (let i = 0; i < cache.slider.length; ++i) {
        cache.slider[i].querySelector('.arrow_left').addEventListener("click", slideLeft);
        cache.slider[i].querySelector('.arrow_right').addEventListener("click", slideRight);

        let hammertime = new Hammer(cache.slider[i]);
        hammertime.on('swipe', function(ev) {
            if(ev.direction == 2) {
                cache.slider[i].querySelector('.arrow_right').click();
            }
            if(ev.direction == 4) {
                cache.slider[i].querySelector('.arrow_left').click();
            }
        });
      }
    };

    let slideLeft = function() {
        const slider = this.closest('.slider-hero');
        const slides = slider.querySelectorAll('.slide');
        const sliderNav = this.closest('.slider-hero_nav');
        let index = parseInt(slider.dataset.index);
        sliderNav.style.pointerEvents = "none";

        const currentSlide = slides[index];

        let prevIndex = 0;
        if(index !== slides.length-1) {
            prevIndex = index+1;
        }
        const prevSlide = slides[prevIndex];

        if(index == 0) {
            index = slides.length-1;
        } else {
            index--;
        }
        slider.dataset.index = index;
        const targetSlide = slides[index];

        let nxtIndex = slides.length-1;
        if(index !== 0) {
            nxtIndex = index-1;
        }
        const nxtSlide = slides[nxtIndex];
        nxtSlide.style.display = "block";
        

        anime({
            targets: prevSlide,
            duration: 800,
            translateY: ['-50%','-50%'],
            translateX: ['40%','150%'],
            scale: [0.77, 0.44],
            easing: 'easeInOutCubic',
            complete: function() {
                prevSlide.style.display = "none";
            }
        });
        anime({
            targets: currentSlide,
            duration: 800,
            delay: 100,
            translateY: ['-50%','-50%'],
            translateX: ['-50%','40%'],
            opacity: 0.4,
            scale: [1, 0.77],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: targetSlide,
            duration: 800,
            delay: 200,
            translateY: ['-50%','-50%'],
            translateX: ['-140%','-50%'],
            opacity: [0.4, 1],
            scale: [0.77, 1],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: nxtSlide,
            duration: 800,
            delay: 250,
            translateY: ['-50%','-50%'],
            translateX: ['-250%','-140%'],
            opacity: [0.4, 0.4],
            scale: [0.44, 0.77],
            easing: 'easeInOutCubic',
            complete: function() {
                sliderNav.style.pointerEvents = "all";
            }
        });
    }

    let slideRight = function() {
        const slider = this.closest('.slider-hero');
        const slides = slider.querySelectorAll('.slide');
        const sliderNav = this.closest('.slider-hero_nav');
        let index = parseInt(slider.dataset.index);
        sliderNav.style.pointerEvents = "none";

        const currentSlide = slides[index];

        let nxtIndex = slides.length-1;
        if(index !== 0) {
            nxtIndex = index-1;
        }
        const nxtSlide = slides[nxtIndex];
        
        if(index == slides.length-1) {
            index = 0;
        } else {
            index++;
        }
        slider.dataset.index = index;
        const targetSlide = slides[index];

        let prevIndex = 0;
        if(index !== slides.length-1) {
            prevIndex = index+1;
        }
        const prevSlide = slides[prevIndex];
        prevSlide.style.display = "block";
        
        anime({
            targets: nxtSlide,
            duration: 800,
            translateY: ['-50%','-50%'],
            translateX: ['-140%','-250%'],
            opacity: [0.4, 0.4],
            scale: [0.77, 0.44],
            easing: 'easeInOutCubic',
            complete: function() {
                nxtSlide.style.display = "none";
            }
        });
        anime({
            targets: currentSlide,
            duration: 800,
            delay: 100,
            translateY: ['-50%','-50%'],
            translateX: ['-50%','-140%'],
            opacity: 0.4,
            scale: [1, 0.77],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: targetSlide,
            duration: 800,
            delay: 200,
            translateY: ['-50%','-50%'],
            translateX: ['40%','-50%'],
            opacity: [0.4, 1],
            scale: [0.77, 1],
            easing: 'easeInOutCubic',
        });
        anime({
            targets: prevSlide,
            duration: 800,
            delay: 250,
            translateY: ['-50%','-50%'],
            translateX: ['150%','40%'],
            opacity: [0.4, 0.4],
            scale: [0.44, 0.77],
            easing: 'easeInOutCubic',
            complete: function() {
                sliderNav.style.pointerEvents = "all";
            }
        });
    }
    initFunc();
  };
}
export { Module_01_HeaderSlider }